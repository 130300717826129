import { environment as original } from './environment.dev';

export const environment = {
  ...original,
  name: 'Oongalee ExcuseMe - Default - Without ExcuseMe - DEV',
  machineName: 'default.without-excuseme.dev',
  featureToggle: {
    ...original.featureToggle,
    withCallWaiterMain: false,
    withCallWaiterAnotherRound: false,
    withCallWaiterRequestBill: false,
    withServiceCentre: false,
  },
};
